import { DrupalNode } from "next-drupal";
import Link from "next/link";
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

import { absolutePublicUrl } from "lib/utils";

interface NodeCampaignsSliderProps {
    campaigns: DrupalNode[]
}

export function NodeCampaignsSlider({ campaigns }: NodeCampaignsSliderProps) {
    const slideContent = () => {
        return (
            <div className="homepage--red_grid--slider">
                {campaigns?.length > 0 && (
                    campaigns.map((node,index) => {
                        let campaign_link = node.path?.alias ? node.path.alias : '/node/' + node.drupal_internal__nid;
                        let image = node.field_hp_image.field_media_image?.image_style_uri?.medium
                            ? absolutePublicUrl(node.field_hp_image.field_media_image.image_style_uri.medium)
                            : absolutePublicUrl(node.field_hp_image.field_media_image.uri.url);

                        const style: React.CSSProperties = {
                            backgroundImage: `url(${image})`
                        };

                        return (
                            <div className={"homepage--red_grid--slider--item"} key={"grid-item-" + (index + 1)}>
                                <Link href={campaign_link} title={node.title}>
                                    {/* <img src={image} alt={node.title}/> */}
                                    <div className="homepage--red_grid--slider--item--bg">
                                        <div className="homepage--red_grid--slider--item--bg_inner" style={style}>
                                        </div>
                                    </div>

                                    <div className="homepage--red_grid--slider--item--text">
                                        <span>{node.field_client}</span> — <span>{node.title}</span>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                )}
            </div>
        )
    }

    return (
        <Swiper
            loop={true}
            slidesPerView = {1}
            autoplay={{
                delay: 0,
                disableOnInteraction: false,
            }}
            speed = {100000}
            modules={[Autoplay]}
        >
            <SwiperSlide>
                { slideContent }
            </SwiperSlide>
            <SwiperSlide>
                { slideContent }
            </SwiperSlide>
        </Swiper>
    )
}
